<template>
  <div
    ref="zone"
    class="relative grid place-content-center rounded-lg border-2 border-dashed transition-all border-primary-100 p-8 duration-500"
    :class="{ '!border-primary bg-primary-50': isOverDropZone }"
    data-cy-dropzone
  >
    <div>
      <slot />
    </div>

    <app-pop-transition>
      <div
        v-show="isOverDropZone"
        class="absolute bottom-2 w-full left-0 grid place-content-center"
      >
        <app-icon
          class="shadow-lg animate-bounce block text-white bg-primary-700 rounded-full aspect-square p-2"
          icon="ph:file-arrow-down"
          :size="40"
        />
      </div>
    </app-pop-transition>
  </div>
</template>

<script lang="ts" setup>
const properties = defineProps<{
  accept?: string[];
}>();

const emit = defineEmits<{
  drop: [payload: File[] | null];
}>();

const zone = templateRef<HTMLDivElement>("zone");
const { isOverDropZone } = useDropZone(zone, {
  dataTypes: properties.accept,
  onDrop(file) {
    emit("drop", file);
  },
});
</script>
